import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { getFilmId, getPersonIdSpotlight } from '../api/services';
import { SpotlightPerson } from '../api/types';

interface AboutModel
{
}

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {

  id!: string | null;

  model$: Observable<AboutModel> | undefined;
  person!: SpotlightPerson;

  constructor(){}
  ngOnDestroy(): void {
      
  }

  ngOnInit(): void {
      this.id = '6420549911adb9fca6851cc4';
      var self = this;
      var person$: SpotlightPerson = {};

      if(this.id == null) {
        return;
      }

      this.model$ = combineLatest([person$])
              .pipe(
                  map(([person]) => {
                      return { 
                          person
                          };}));

      var promise = getPersonIdSpotlight(this.id)
          .then(result => {
            person$ = result;
              this.person = person$;

              this.model$ = combineLatest([person$])
              .pipe(
                  map(([person]) => {
                      return { 
                        person
                          };}));
          });
  } 

}