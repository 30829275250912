<nav class="navbar navbar-expand-lg fixed-top nav-down navbar-transparent" color-on-scroll="500">
    <div class="container">
        <div class="navbar-translate">
            <div class="navbar-header">
                <a class="navbar-brand" [routerLink]="['']">The Creators Productions</a>
            </div>
            <button class="navbar-toggler navbar-burger" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation" (click)="sidebarToggle()">
                <span class="navbar-toggler-bar bar1"></span>
                <span class="navbar-toggler-bar bar2"></span>
                <span class="navbar-toggler-bar bar3"></span>
            </button>
        </div>
        <div class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto">
                <div ngbDropdown class="nav-item dropdown">
                    <div *ngIf="films.length > 0"  class="nav-link dropdown-toggle" ngbDropdownToggle>Films</div>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right dropdown-danger">
                        <a *ngFor="let film of films" ngbDropdownItem [routerLink]="['/records', 'film', film.id]">{{film.name}}</a>    
                    </div>
                </div>
                <div ngbDropdown class="nav-item dropdown">
                    <div *ngIf="television.length > 0" class="nav-link dropdown-toggle" ngbDropdownToggle>Television</div>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right dropdown-danger">
                        <div *ngFor="let tv of television">
                            <a ngbDropdownItem [routerLink]="['/records/', 'television', tv.id]">{{tv.name}}</a>    
                        </div>
                    </div>
                </div>
                <li class="nav-item">
                    <a class="btn btn-round btn-default"  [routerLink]="['/about-us']">
                        About Us
                    </a>
                </li>
                <li>
                    <a href="http://www.instagram.com/thecreatorsproductions" class="btn btn-neutral btn-link btn-just-icon">
                        <i class="fa fa-instagram fa-3x" style="font-size: 3em;top: -10px" aria-hidden="true"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
