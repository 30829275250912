import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { getTvId } from 'src/app/api/services';
import { TvRecord } from 'src/app/api/types';

interface TvDetailModel
{
    tv: TvRecord
}

@Component({
  selector: 'app-television-detail',
  templateUrl: './television-detail.component.html',
  styleUrls: ['./television-detail.component.scss']
})
export class TelevisionDetailComponent implements OnInit, OnDestroy {

  id!: string | null;

  model$: Observable<TvDetailModel> | undefined;
  record!: TvRecord;

  constructor(private route: ActivatedRoute, private router: Router){}
  ngOnDestroy(): void {
      
  }

  ngOnInit(): void {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      
      this.id = this.route.snapshot.paramMap.get('id');
      console.log(this.id);
      var self = this;
      var record$: TvRecord = {

      };

      if(this.id == null) {
        return;
      }

      this.model$ = combineLatest([record$])
              .pipe(
                  map(([tv]) => {
                      return { 
                              tv
                          };}));

      var promise = getTvId(this.id)
          .then(result => {
              record$ = result;
              this.record = record$;
              console.log(this.record.socialMedia?.length);

              this.model$ = combineLatest([record$])
              .pipe(
                  map(([tv]) => {
                      return { 
                              tv
                          };}));
          });
  } 

}