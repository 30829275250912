<div *ngIf="person">

    <div class="wrapper">
        <div class="page-header page-header-xs" [style.background-image]="'url(./assets/img/tcp/creators/jeremy-yap.jpg)'">
            <div class="filter"></div>
        </div>
    

    </div>
    <div class="project-4 section section-dark" style="height:100vh">
    <div class="container">
        <div class="row about-us">
            <div class="col-md-5 ml-auto">
                <div class="card" data-background="image" [style.background-image]="'url(./assets/img/tcp/creators/' + person.id + '.jpg)'">
                </div>
            </div>

            <div class="col-md-5 mr-auto">
                <div class="card card-plain">
                    <div class="card-body">
                        <h6 class="card-category">{{person.title}}</h6>
                        <a href="javascript:void(0)">
                            <h3 class="card-title">{{person.displayName}}</h3>
                        </a>
                        <p class="card-description">{{person.statement}}</p>

                        <div class="card-footer">
                            <span *ngFor="let item of person.socialMedia; let idx = index">
                                <a [attr.href]="item.link" class="btn btn-neutral btn-link btn-just-icon">
                                    <i class="fa fa-instagram fa-2x" style="font-size: 2em;top:-10px;position:relative" aria-hidden="true"></i>
                                </a>
                            </span>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <hr>

        <div class="row about-us">

            <div class="col-md-6 mr-auto">
                <div class="card card-plain">
                    <div class="card-body">

                        <h4 class="card-description">"Unlocking the imagination, through creation"</h4>
                        <h3 class="card-title" style="text-align:right;">- The Creator Productions</h3>

                        <div class="card-footer">
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-md-6 ml-auto">
                <div class="card" data-background="image" [style.background-image]="'url(./assets/img/tcp/creators_logo.jpg)'">
                </div>
            </div>   
        </div>
        </div>
</div>
</div>
