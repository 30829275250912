import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, combineLatest, map } from 'rxjs';
import { getFilmId } from 'src/app/api/services';
import { FilmRecord } from 'src/app/api/types';

interface FilmDetailModel
{
    film: FilmRecord
}

@Component({
  selector: 'app-film-detail',
  templateUrl: './film-detail.component.html',
  styleUrls: ['./film-detail.component.scss']
})
export class FilmDetailComponent  implements OnInit, OnDestroy {

  id!: string | null;

  model$: Observable<FilmDetailModel> | undefined;
  record!: FilmRecord;

  constructor(private route: ActivatedRoute, private router: Router){}
  ngOnDestroy(): void {
      
  }

  ngOnInit(): void {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      
      this.id = this.route.snapshot.paramMap.get('id');
      console.log(this.id);
      var self = this;
      var record$: FilmRecord = {

      };

      if(this.id == null) {
        return;
      }

      this.model$ = combineLatest([record$])
              .pipe(
                  map(([film]) => {
                      return { 
                              film
                          };}));

      var promise = getFilmId(this.id)
          .then(result => {
              record$ = result;
              this.record = record$;

              this.model$ = combineLatest([record$])
              .pipe(
                  map(([film]) => {
                      return { 
                              film
                          };}));
          });
  } 

}
