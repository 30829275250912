<div class="wrapper">

    <div class="keen-slider" #sliderRef>
        <div
          [ngStyle]="{ opacity: opacities[idx] }"
          class="fader__slide number-slide-solid"
          *ngFor="let record of records; let idx = index"
        >
          <img src="./assets/img/tcp/{{record.id}}.jpg" />
        </div>
      </div>

      <div class="sub-menu" *ngIf="slider">
        <div *ngFor="let record of records; let idx = index">

        <div *ngIf="record.recordType; then dynamiclink; else staticlink"></div>

        <ng-template #dynamiclink>
            <a [routerLink]="['/records/', record.recordType?.toLowerCase(), record.id]" (mouseover)="moveToSlide(idx)">
                <h3 class="sub-menu-text">{{record.name}}</h3>
            </a>
        </ng-template>

        <ng-template #staticlink>
            <h3 class="sub-menu-text"  (mouseover)="moveToSlide(idx)">{{record.name}}</h3>
        </ng-template>

        </div> 
      </div>

</div>

<footer class="footer footer-black">
  <div class="container">
    <div class="row">
      <nav class="footer-nav">
        <ul>
          <li><a href="http://www.thecreatorsproductions.com">The Creators Productions</a></li>
        </ul>
      </nav>
      <div class="credits ml-auto">
        <span class="copyright">
          © {{date | date: 'yyyy'}} Clarity CI
        </span>
      </div>
    </div>
  </div>
</footer>
