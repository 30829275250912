import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FilmDetailComponent } from './film/film-detail/film-detail.component';
import { HomeComponent } from './home/home.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TelevisionDetailComponent } from './television/television-detail/television-detail.component';
import { AboutComponent } from './about/about.component';

const routes: Routes = [
  { path: 'home',                       component: HomeComponent },
  { path: 'records/film/:id',           component: FilmDetailComponent },
  { path: 'records/television/:id',     component: TelevisionDetailComponent },
  { path: 'about-us',                   component: AboutComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
