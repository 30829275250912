import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FilmDetailComponent } from './film/film-detail/film-detail.component';
import { CreatorSpotlightComponent } from './creators/creator-spotlight/creator-spotlight.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { TelevisionDetailComponent } from './television/television-detail/television-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FilmDetailComponent,
    CreatorSpotlightComponent,
    NavbarComponent,
    AboutComponent,
    TelevisionDetailComponent
  ],
  imports: [
    BrowserAnimationsModule,
    NgbModule,
    CommonModule,
    FormsModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
