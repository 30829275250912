<div *ngIf="record">

    <div class="wrapper">
        <div class="page-header page-header-xs" [style.background-image]="'url(./assets/img/tcp/films/' + record.id + '.jpg)'">
            <div class="filter"></div>
        </div>
        <div class="sub-menu">
            <h1 class="sub-menu-text">{{record.name}} 
                <span *ngFor="let item of record.socialMedia; let idx = index">
                    <a [attr.href]="item.link" class="btn btn-neutral btn-link btn-just-icon">
                        <i class="fa fa-instagram fa-3x" style="font-size: 3em;top:-10px;position:relative" aria-hidden="true"></i>
                    </a>
                </span>
            </h1>
        </div>
        <div class="main">
            <div class="section">
                <div class="container">
                    <div class="row title-row">
                        <div class="col-md-4">
                            <span class="text-muted">Written By</span>
                            <div *ngFor="let person of record.writtenBy">
                                <h5 style="font-weight:bold">
                                    {{person.name}}
                                </h5>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <span class="text-muted">Directed By</span>
                            <div *ngFor="let person of record.directedBy">
                                <h5 style="font-weight:bold">{{person.name}}</h5>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <span class="text-muted">Releasing</span>
                            <h5 style="font-weight:bold">{{record.releaseDate ?? 'Soon'}}</h5>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <h3 style="text-align:center">{{record.synopsis}}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    

    
</div>
