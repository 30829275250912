import { Component, ElementRef, OnInit } from '@angular/core';
import { getRecordUpcoming } from 'src/app/api/services';
import { GetRecordUpcomingQueryParams, RecordResult } from 'src/app/api/types';
import {Location} from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  private toggleButton: any;
  private sidebarVisible: boolean;

  public films: RecordResult[] = [];
  public television: RecordResult[] = [];

  constructor(public location: Location, private element : ElementRef) {
      this.sidebarVisible = false;
  }
  ngOnInit(): void {
    const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];

        var self = this;
        getRecordUpcoming(<GetRecordUpcomingQueryParams>{ maxResults: 50 })
        .then(results => {
            for(const result of results){
                if(result.recordType == 'Film'){
                    self.films.push(result); 
                } else if(result.recordType == 'Television'){
                    self.television.push(result);
                }
            }

            console.log('films:' + self.films.length);
            console.log('television:' + self.television.length);
        });
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    setTimeout(function(){
        toggleButton.classList.add('toggled');
    }, 500);
    html.classList.add('nav-open');

    this.sidebarVisible = true;
};

sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    // console.log(html);
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
};

sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
        this.sidebarOpen();
    } else {
        this.sidebarClose();
    }
};

}
