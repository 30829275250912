import { AfterViewInit, Component, ElementRef, Injectable, OnDestroy, ViewChild } from '@angular/core';
import KeenSlider, { KeenSliderInstance, TrackDetails } from 'keen-slider';
import { getRecordUpcoming } from '../api/services';
import { RecordResult, GetRecordUpcomingQueryParams } from '../api/types';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [
      '../../../node_modules/keen-slider/keen-slider.min.css',
      './home.component.scss'
  ]
})
@Injectable()
export class HomeComponent implements AfterViewInit, OnDestroy {
  
  @ViewChild("sliderRef")
  sliderRef!: ElementRef<HTMLElement>;
  slider!: KeenSliderInstance;
  details: TrackDetails | undefined;
  opacities: number[] = [];
  records : RecordResult[] = [<RecordResult>{id: 'creators_logo', name: ''}];
  date : Date = new Date();

  constructor() { }

  moveToSlide(index: number){
      console.log(index);
      this.slider.moveToIdx(index);
  }

  ngAfterViewInit() {
    return;
    
      getRecordUpcoming(<GetRecordUpcomingQueryParams>{ maxResults: 5 })
      .then(results => {
          for(const result of results){
              this.records.push(result); 
          }

          setTimeout(() => {
              this.slider = new KeenSlider(this.sliderRef.nativeElement, {
                slides: this.records.length,
                loop: true,
                detailsChanged: (s) => {
                  this.opacities = s.track.details.slides.map((slide) => slide.portion);
                },
              },
              [
                (slider) => {
                  let timeout: NodeJS.Timeout;
                  let mouseOver = false
                  function clearNextTimeout() {
                    clearTimeout(timeout)
                  }
                  function nextTimeout() {
                    clearTimeout(timeout);
                    if (mouseOver) return
                    timeout = setTimeout(() => {
                      slider.next()
                    }, 5000);
                  }
                  slider.on("created", () => {
                    slider.container.addEventListener("mouseover", () => {
                      mouseOver = true
                      clearNextTimeout()
                    })
                    slider.container.addEventListener("mouseout", () => {
                      mouseOver = false
                      nextTimeout()
                    })
                    nextTimeout()
                  })
                  slider.on("dragStarted", clearNextTimeout)
                  slider.on("animationEnded", nextTimeout)
                  slider.on("updated", nextTimeout)
                },
              ])
            });
      });
    }

  ngOnDestroy(){
      if (this.slider) {
          this.slider.destroy();
      }
  }
}

